<template>
  <div>
    <template v-if="is_receptionist">
      <professional-details-as-assistant
        :professional-id="parseInt($route.params.id)"
        :professional="professional"
        :loading="loading"
        @editingData="editData"
      />
    </template>
    <template v-else>
      <usuario
        :idusuario="$route.params.id"
        :preselected-tab="tab"
        :professional-uuid="professional.uuid"
        :request-two-factor-reset="professional.requestTwoFactorReset"
        @modificando="editData"
      />
    </template>

    <div class="text-center">
      <v-dialog v-model="dialogExitWithoutSaving" width="500">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('common.titulo_salir_sin_guardar') }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="dialogExitWithoutSaving = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="px-4">{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-2" dark text @click="dialogExitWithoutSaving = false">{{
              $t('common.cancel')
            }}</v-btn>
            <v-btn color="primary" dark depressed @click="ConfirmAction">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import usuario from '@/components/ehealth/Usuario.vue';
import ProfessionalDetailsAsAssistant from '@/components/professionals/ProfessionalDetailsAsAssistant';
import { getProfessional } from '@/views/professionals/professionals.api';

export default {
  components: { ProfessionalDetailsAsAssistant, usuario },
  props: {
    tab: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    is_receptionist:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
    dialogExitWithoutSaving: false,
    pageNext: {},
    editingData: false,
    loading: true,
    professionalId: 0,
    professional: {
      id: vm.$route.params.id,
      phone: {},
      location: {},
      landing: {
        biography: {
          es: '',
          en: '',
        },
      },
    },
  }),

  mounted() {
    this.professionalId = this.$route.params.id;
    this.loadProfessional();
  },

  created() {
    if (!(this.authUser !== null && (this.authUser.rol === 1 || this.authUser.rol === 3))) {
      this.$router.push('/professionals');
    }
  },

  methods: {
    async loadProfessional() {
      this.professional = await getProfessional(this.professionalId, '/professionals');
      this.loading = false;
    },

    editData(val) {
      this.editingData = val;
    },

    ConfirmAction() {
      this.dialogExitWithoutSaving = false;
      this.pageNext();
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.editingData && to['name'] !== 'Login') {
      this.dialogExitWithoutSaving = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>
<style scoped lang="css"></style>
