<template>
  <v-card flat>
    <v-card-text>
      <form class="formPersonalData">
        <v-layout wrap>
          <v-flex sm6 lg6>
            <v-text-field
              v-model="professional.name"
              class="mr-2"
              :label="$t('patient.first_name')"
              type="text"
              required
              @change="modifySlug"
            />
          </v-flex>
          <v-flex sm6 lg6>
            <v-text-field
              v-model="professional.surname"
              class="mr-2"
              :label="$t('patient.last_name')"
              type="text"
              required
              @change="modifySlug"
            />
          </v-flex>
          <v-flex sm6 lg6>
            <v-text-field
              v-model="professional.email"
              class="mr-2"
              :label="$t('message.email')"
              append-icon="mdi-email"
              type="alternate_email"
              required
              @change="editData"
            />
          </v-flex>
          <v-flex sm6 lg6>
            <v-text-field
              v-model="professional.email_repeat"
              onpaste="return false"
              class="mr-2"
              :label="$t('message.email_repeat') + ' *'"
              append-icon="mdi-at"
              type="mail"
              required
              @change="editData"
            />
          </v-flex>
          <v-flex xs12 sm4 lg4>
            <v-text-field
              v-model="professional.collegiateNumber"
              class="mr-2"
              :label="$t('user.num_colegiado')"
              type="text"
              @change="editData"
            />
          </v-flex>
          <v-flex xs12 sm4 lg4>
            <v-text-field
              v-model="professional.idNumber"
              class="mr-2"
              :label="$t('patient.document_id')"
              type="text"
              required
            />
          </v-flex>
          <v-flex xs6 sm4 lg4>
            <v-text-field
              v-model="professional.phone.phone"
              class="mr-2"
              :label="$t('patient.phone')"
              append-icon="mdi-phone"
              type="text"
              required
              @change="editData"
            />
          </v-flex>
          <v-flex sm9 lg9>
            <v-text-field
              v-model="professional.location.address"
              class="mr-2"
              :label="$t('common.address')"
              append-icon="mdi-map-marker"
              type="text"
              required
              @change="editData"
            />
          </v-flex>
          <v-flex sm3 lg3>
            <v-text-field
              v-model="professional.location.postalCode"
              class="mr-2"
              :label="$t('common.cp')"
              append-icon="mdi-map-marker"
              type="text"
              required
              @change="editData"
            />
          </v-flex>
          <v-flex sm6 lg6>
            <v-text-field
              v-model="professional.location.city"
              class="mr-2"
              :label="$t('common.location')"
              append-icon="mdi-map-marker"
              type="text"
              required
              @change="editData"
            />
          </v-flex>
          <v-flex sm6 lg6>
            <v-text-field
              v-model="professional.location.province"
              class="mr-2"
              :label="$t('common.province')"
              append-icon="mdi-map-marker"
              type="text"
              required
              @change="editData"
            />
          </v-flex>
          <v-flex xs12 sm6 lg6>
            <label class="label_gender">{{ $t('user.tipo_prof') }}:</label>
            <v-radio-group v-model="professional.treatment" class="radio_gender" row @change="modifySlug">
              <v-radio value="0" :label="$t('common.none')" color="grey" />
              <v-radio :value="$t('user.female_doctor')" :label="$t('user.female_doctor')" color="pink" />
              <v-radio :value="$t('user.male_doctor')" :label="$t('user.male_doctor')" color="info" />
            </v-radio-group>
          </v-flex>
          <v-flex xs12 sm6 lg6>
            <v-select
              v-model="professional.language"
              :items="languages"
              :label="$t('common.lang')"
              auto
              required
              item-text="name"
              item-value="id"
            />
          </v-flex>
          <v-flex xs12 sm6 lg6>
            <v-select
              v-model="professional.timezone"
              :items="timezones"
              :label="$t('user.professional_time_zone')"
              auto
              required
              item-text="name"
              item-value="id"
            />
          </v-flex>
          <v-flex xs12 sm6 lg6>
            <v-menu
              ref="birthDateMenu"
              v-model="birthDateMenu"
              class="mr-2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              :return-value.sync="professional.dateOfBirth"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateFormatted"
                  :label="$t('patient.birthdate')"
                  append-icon="mdi-calendar"
                  readonly
                  class="input_fnacimiento"
                  v-on="on"
                  @blur="date = parseDate(dateFormatted)"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="date"
                first-day-of-week="1"
                :locale="this.$i18n.locale"
                :min="datePickerMinDate"
                :max="new Date().toISOString().substr(0, 10)"
                no-title
                scrollable
                @input="$refs.birthDateMenu.save(date)"
              />
            </v-menu>
          </v-flex>
        </v-layout>
      </form>
    </v-card-text>
  </v-card>
</template>

<script>
import { datePickerMinDate } from '@/services/dateHelper';

export default {
  props: {
    professional: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      languages: [
        { id: 'es', name: vm.$t('common.spanish') },
        { id: 'en', name: vm.$t('common.english') },
      ],
      timezones: [
        { id: 'Europe/Madrid', name: 'Europe/Madrid' },
        { id: 'Atlantic/Canary', name: 'Atlantic/Canary' },
        { id: 'America/Santiago', name: 'America/Santiago' },
      ],
      date: null,
      dateFormatted: null,
      birthDateMenu: false,
      datePickerMinDate: datePickerMinDate(),
    };
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    professional(val) {
      this.date = val.dateOfBirth;
    },
    date(_val) {
      this.dateFormatted = this.formatDate(this.date);
    },

    birthDateMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
        });
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    modifySlug() {
      let slug = '';
      let publicName = '';

      if (
        this.professional.treatment !== '0' &&
        this.professional.treatment !== 0 &&
        this.professional.treatment !== ''
      ) {
        const pref = this.professional.treatment.replace('.', '');
        slug += pref.toLowerCase() + '-';
        publicName = this.professional.treatment;
      }
      if (this.professional.name !== '') {
        let name = this.professional.name.trim();
        publicName += publicName === '' ? name : ' ' + name;
        name = name.replace(/\s/g, '-');
        name = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += name.toLowerCase() + '-';
      }
      if (this.professional.surname !== '') {
        let surname = this.professional.surname.replace(/\s/g, '-');
        publicName += ' ' + this.professional.surname;
        surname = surname.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += surname.toLowerCase();
      }
      this.professional.landing.slug = slug;
      this.professional.landing.publicName = publicName;
      this.$emit('editingData', true);
    },

    editData() {
      this.$emit('editingData', true);
    },
  },
};
</script>
<style scoped lang="css">
.formPersonalData {
  padding: 10px;
}
.label_gender {
  margin-bottom: 0;
}
.radio_gender {
  margin-top: 3px;
}
</style>
