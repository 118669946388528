import { http } from '@/core/api';
import { professionalsPath } from '@/config/config';
import { mapProfessionalList } from './professionals.mapper';

export const getProfessionals = (page, itemsPerPage, search) => {
  const url = new URL(professionalsPath);
  const params = {};

  if (page) {
    params.page = page;
  }

  if (itemsPerPage) {
    params.itemsPerPage = itemsPerPage;
  }

  if (search) {
    params.search = search;
  }

  url.search = new URLSearchParams(params).toString();

  return http
    .get(url.toString())
    .then(response => mapProfessionalList(response.data))
    .catch(error => console.error(error));
};

export const getProfessional = (professionalId, viewToReturn) =>
  http
    .get(professionalsPath + '/' + professionalId)
    .then(response => {
      return {
        ...response.data.professional,
        ...response.data.user,
      };
    })
    .catch(() => vm.$router.push(viewToReturn));

export const putProfessional = (professionalId, data) =>
  http
    .put(professionalsPath + '/' + professionalId, data)
    .then(_response => true)
    .catch(function(error) {
      typeof error.response?.data?.error?.code !== 'undefined'
        ? vm.$toast.error(vm.$t('errors.' + error.response.data.error.code))
        : vm.$toast.error(vm.$t('errors.try_again'));
    });
