<template>
  <v-tabs v-model="tabIndex" background-color="grey lighten-3 tabs_mail">
    <v-tab v-for="(item, index) in tabs" :key="index" @change="$emit('updateTab', index)">
      <span>{{ item }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      allTabs: [
        vm.$t('patient.personal_data'),
        vm.$t('user.aditional_info'),
        vm.$t('common.access'),
        vm.$t('menu.servicios'),
      ],
      tabs: [],
      tabIndex: 0,
      isSuperAdmin:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['isSuperAdmin'],
      isAdmin:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth')).rol === 1,
      isReceptionist:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth')).rol === 3,
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      companyPermissions: state => state.account.authUser.company.permissions,
    }),
    showServices() {
      return ((this.permissions.settingsServices !== false && this.isReceptionist) || this.isSuperAdmin || this.isAdmin) && this.companyPermissions?.minorsTreatment;
    }
  },

  mounted() {
    this.allTabs.forEach(tab => {
      switch (tab) {
        case 'Marketplace':
          if (this.permissions.marketplace !== false || this.isSuperAdmin) {
            this.tabs.push(tab);
          }
          break;
        case this.$t('menu.servicios'):
          if (this.showServices) {
            this.tabs.push(tab);
          }
          break;
        default:
          this.tabs.push(tab);
          break;
      }
    });
  },
};
</script>
