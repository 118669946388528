<template>
  <div class="mt-4">
    <div v-if="!loading" class="text-center">
      <v-tooltip v-if="!modifyImage" right>
        <template v-slot:activator="{ on }">
          <v-avatar size="150px" v-on="on" @click="modifyImageHandle()">
            <img class="avatar photo_professional" :src="professional.photo" alt="avatar" />
          </v-avatar>
        </template>
        <span>{{ $t('common.modify_image') }}</span>
      </v-tooltip>
      <croppa
        v-model="myCroppa"
        :class="!modifyImage ? croppaStatus + ' photo_avatar' : 'photo_avatar'"
        :width="200"
        :height="200"
        :placeholder="$t('user.select_image')"
        placeholder-color="#1976D2"
        :placeholder-font-size="10"
        canvas-color="transparent"
        :show-remove-button="true"
        remove-button-color="#1976D2"
        :remove-button-size="36"
        :show-loading="true"
        :loading-size="20"
        :zoom-speed="8"
        :prevent-white-space="true"
        :file-size-limit="5002400"
        @file-size-exceed="onFileSizeExceed"
      />
      <v-btn
        v-if="modifyImage && myCroppa.img !== null"
        class="btn_upload_imagen"
        color="primary"
        depressed
        small
        dark
        @click="uploadImage"
      >
        <v-icon>mdi-cloud-upload</v-icon> {{ $t('common.save') }}
      </v-btn>
      <input
        id="file"
        ref="file"
        type="file"
        accept="application/pdf,image/jpg,image/png,image/gif"
        style="display: none"
        @change="onFileChange"
      />
    </div>
    <v-card v-if="!loading" class="md-card-profile container">
      <v-card-text>
        <form class="form_marketplace">
          <v-layout wrap>
            <v-flex xs12 sm4 lg4>
              <v-text-field
                v-model="professional.landing.slug"
                class="mr-2"
                label="Slug (SEO)"
                type="text"
                @change="editData"
              />
            </v-flex>
            <v-flex xs12 sm4 lg4>
              <v-text-field
                v-model="professional.landing.publicName"
                class="mr-2"
                :label="$t('account.marketplaceName')"
                type="text"
                @change="editData"
              />
            </v-flex>
            <v-flex xs12 sm4 lg4>
              <v-text-field
                v-model="professional.landing.externalPage"
                class="mr-2"
                label="Landing Page"
                append-icon="mdi-tablet-dashboard"
                type="mail"
                @change="editData"
              />
            </v-flex>
            <v-flex sm12 lg12>
              <div slot="widget-content">
                <v-autocomplete
                  v-model="professional.specialties"
                  :label="$t('user.especialidades')"
                  :items="specialties"
                  chips
                  item-text="name"
                  item-value="id"
                  item-valor="id"
                  multiple
                  :readonly="isReceptionist"
                  @click="isReceptionist ? editData() : null"
                >
                  <template slot="selection" slot-scope="data">
                    <v-chip
                      :input-value="data.selected"
                      :close="!isReceptionist"
                      class="chip--select-multi"
                      color="blue lighten-4"
                      @click:close="deleteSpecialty(data.item.id)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template slot="item" slot-scope="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item" />
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-radio-group
                v-model="landingLanguage"
                row
                style="margin-top: -10px; margin-bottom: -20px; float: right"
              >
                <v-radio color="blue darken-3" :label="$t('common.spanish')" value="es" />
                <v-radio color="blue darken-3" :label="$t('common.english')" value="en" />
              </v-radio-group>
            </v-flex>
            <v-flex v-if="landingLanguage === 'es'" xs12 sm12 lg12>
              <v-textarea
                v-model="professional.landing.biography.es"
                style="width: 99%; margin-left: 4px;"
                :label="$t('user.biografia')"
                rows="3"
                placeholder="Escriba aqui el texto en Español"
                @change="editData"
              />
              <div
                style="font-size: 11px;margin-top: -20px;width: 99.5%;"
              >
                {{ professional.landing.biography.es.length }}
              </div>
            </v-flex>
            <v-flex v-if="landingLanguage === 'en'" xs12 sm12 lg12>
              <v-textarea
                v-model="professional.landing.biography.en"
                style="width: 99%; margin-left: 4px;"
                :label="$t('user.biografia')"
                rows="3"
                placeholder="Write here the text in English"
                @change="editData"
              />
              <div
                :class="500 - professional.landing.biography.en.length < 0 ? 'font-red' : ''"
                style="font-size: 11px;margin-top: -20px;width: 99.5%;"
              >
                {{ 500 - professional.landing.biography.en.length }}
              </div>
            </v-flex>
          </v-layout>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { getHeaderMultimedia, professionalsPath } from '@/config/config';

export default {
  props: {
    professional: {
      type: Object,
      default: () => {
        return {};
      },
    },
    specialties: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    isReceptionist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myCroppa: {},
      croppaStatus: 'hide_croppa',
      modifyImage: false,
      landingLanguage: 'es',
    };
  },

  methods: {
    modifyImageHandle() {
      this.modifyImage = true;
      this.croppaStatus = '';
      this.myCroppa.chooseFile();
      const photoElement = document.getElementsByClassName('photo_avatar');
      const canvas = photoElement[0].childNodes[4];
      canvas.style.borderRadius = '50%';
      canvas.style.boxShadow =
        '0 10px 30px -12px rgba(0,0,0,.42), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2)';
    },

    editData() {
      this.$emit('editingData', true);
    },

    onFileSizeExceed(_file) {
      this.$toast.error(this.$t('user.warning_image_exceeds_maximum', { size: '1024KB' }));
    },

    onFileChange() {
      const newPhoto = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append('file', newPhoto);
      formData.append('professionalId', this.professional.id);
    },

    uploadImage() {
      if (typeof this.myCroppa.img !== 'undefined' && this.myCroppa.img !== null) {
        const formData = new FormData();
        formData.append('professionalId', this.professional.id);
        this.myCroppa.generateBlob(blob => {
          formData.append('file', blob, 'filename.png');
          this.$http
            .post(professionalsPath + '/' + this.professional.id + '/image', formData, {
              headers: getHeaderMultimedia(),
            })
            .then(response => {
              this.professional.photo = response.data.photo;
              this.$toast.success(this.$t('success.save_common'));
              this.modifyImage = false;
              this.croppaStatus = 'hide_croppa';
              this.myCroppa.img = null;
            })
            .catch(_err => {
              this.$toast.error(this.$t('errors.try_again'));
            });
        });
      }
    },

    deleteSpecialty(item) {
      this.professional.specialties.splice(this.professional.specialties.indexOf(item), 1);
      this.professional.specialties = [...this.professional.specialties];
      this.editData();
    },
  },
};
</script>
<style scoped lang="css">
.avatar {
  z-index: 1;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.photo_avatar {
  position: relative;
  z-index: 1;
}
.hide_croppa {
  display: none;
}
.photo_professional:hover {
  box-shadow: 0 10px 30px -12px #2196f369, 0 4px 25px 0 #2196f370, 0 8px 10px -5px rgba(33, 150, 243, 0.3);
}
.md-card-profile {
  width: 96%;
  margin: -25px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.btn_upload_imagen {
  position: absolute !important;
  z-index: 100;
  top: 135px;
  margin-left: 83px;
  -webkit-animation: enfasis_btn 1s infinite alternate;
}
@keyframes enfasis_btn {
  from {
    width: 140px;
    height: 40px;
    top: 135px;
    margin-left: 43px;
  }
  to {
    width: 150px;
    height: 50px;
    top: 128px;
    margin-left: 38px;
  }
}
</style>
