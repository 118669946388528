<template>
  <div v-if="showServices" class="pa-5">
    <v-row>
      <v-col xs="12" sm="12" md="12">
        <v-card flat>
          <v-card-title class="grey lighten-4">
            <v-icon color="black" class="mr-4">mdi-account-child</v-icon>
            {{ $t('minors_attention.title') }}
          </v-card-title>
          <v-card-text class="minors-attention-service__container">
            <AgeGroupSpecialties v-model="ageGroupSpecialtiesRelation" :professional-id="userId" :professional-specialties="professionalSpecialties" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AgeGroupSpecialties from "@/MinorsTreatment/AgeGroupSpecialties/sections/AgeGroupSpecialties.vue";
import {mapState} from "vuex";

export default {
  components: {AgeGroupSpecialties },
  props: {
    userId: {
      type: String,
      default: null
    },
    professionalSpecialties: {
      type: Array,
      default: function () {
        return [];
      },
    }
  },
  data: function () {
    return {
      isSuperAdmin:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['isSuperAdmin'],
      isAdmin:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth')).rol === 1,
      isReceptionist:
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth')).rol === 3,
      ageGroupSpecialtiesRelation: null,
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
    showServices() {
      return ((this.permissions.settingsServices !== false && this.isReceptionist) || this.isSuperAdmin || this.isAdmin)
    },
    ageGroupRelationFormatted() {
      if (this.ageGroupSpecialtiesRelation === null) {
        return null;
      }
      return this.ageGroupSpecialtiesRelation.map((relation) => {
        return {
          specialtyId: relation.id,
          ageGroup: relation.ageGroup,
        }
      })
    }
  },
  watch: {
    ageGroupRelationFormatted: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true,
    }
  }
};
</script>

<style lang="scss">
.minors-attention-service__container {
  padding-top: 2.25rem !important;
}
</style>
