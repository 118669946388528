export const mapProfessionalApiToVm = professional =>
  professional && {
    id: professional.id,
    name: professional.name,
    surname: professional.surname,
    publicName: professional.publicName,
    photo: professional.photo,
    email: professional.email,
    createdAt: professional.createdAt,
    company: professional.company,
    status: professional.status,
    uuid: professional.uuid,
  };

export const mapProfessionalsApiToVm = professionals =>
  Array.isArray(professionals) ? professionals.map(mapProfessionalApiToVm) : [];

export function mapProfessionalList(data) {
  return {
    items: mapProfessionalsApiToVm(data.items),
    meta: data.meta,
  };
}
